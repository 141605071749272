import React from "react"
import LayoutEng from "../../components/LayoutEng"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../../components/SEO"

const AnaMiletic = () => {
  return (
    <LayoutEng>
      <SEO title="AnaMiletic" description="Psychologist, Gestalt and EMDR Psychotherapist working both online and in-person. Services accessible in English and Croatian and situated in Zagreb, Croatia" />
      <main className="page">
      <div className="profilePage">
            <div>
              <StaticImage
              src="../../assets/images/ana.jpeg"
              alt="Ana_Miletic"
              className="ana-profile-img"
              placeholder="DOMINANT_COLOR"
              />
            </div>
            <div className="profileText">
                <h1><strong>Ana Miletić</strong> <br class="mobile-break"/><span className="kurziv">prof. psych.</span></h1>
                <h3>Wellcome!</h3>
                <p> I am looking forward to meeting you and following you in your journey of exploration and change. 
                  It might be difficult at times, but I promise we're in this together.
                </p>
                <p>I was always fascinated with people. In psychotherapy I have found the best use of my curiosity 
                  and my presence. It's a privilege to be near the most vulnerable human experiences so I aim to be 
                  both gentle and bold in approaching them and going through them with my clients. On that journey with me, 
                  you can find understanding, support and trust in yourself and your capabilities.
                </p>
                <p>My desire to dig deep into human soul has led me to study psychology and later gestalt psychotherapy. 
                  When I was choosing the school of psychotherapy, I especially liked that gestalt goes into the root of 
                  the issues and stresses the importance of personal psychotherapy for the trainees and first-hand 
                  experience of what it means to be the client and to go through personal growth. 
                  That experience has increased my ability to be present with you, to sympathize with your experience 
                  and to provide hope that the change is possible. In my experience, we do not want to stop once we are on 
                  that journey of increasing awareness and freedom. I continue my growth through training in specific 
                  topics in psychotherapy (<Link to="../../Services/psychotherapy">EMDR</Link> technique for working with trauma), supervision and becoming trainer for 
                  other future psychotherapists.
                </p>
                <p>Before Ivana and I started Apsiha as place where we can combine our experiences and knowledge for the well-being of our clients, 
                  I have worked in organisational psychology. Together with great colleagues, 
                  I was working on creating development programs for improving interpersonal skills, 
                  developing HR processes that promote transparency and acknowledgement and measuring and managing organisational culture. 
                  I still work in that field through Employee Assistance Programs <Link to="../../Services/employee_wellbeing">EAP</Link>  and well-being trainings.
                </p>
                <p>Besides work, my life is filled with small and big family, friends, singing and music, bicycle riding and exercising, 
                  cooking and enjoying good food, vine and coffee, movies and tv series, difficulties and lightness. 
                </p>
                <p>I am here for you if you decide to reach out to me. Check our services and I look forward to meeting you!
                </p>
            </div>
            <div className="backBtn">
              <Link to="../About/#ana" className="btn">
              &laquo; Back
              </Link>
            </div>
            </div>  
      </main>
    </LayoutEng>
  )
}

export default AnaMiletic
